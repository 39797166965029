import React, { useEffect, useState } from "react";
import { Typography, Paper } from "@mui/material";
import ReactGA from "react-ga4";
import useCustomStyles from "../styles/customStyles";
import DataTable from "../components/DataTable";
import axios from "axios";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import { getUserStatusString } from "../utils/security-utils";

function ManageUsers(props) {
  const [users, setUsers] = useState([]);
  const fetchUserData = async () => {
    const apiUrl = getApiUrl() + "user/list";
    //const params = new URLSearchParams([["id", id]]);

    axios
      .get(apiUrl, { withCredentials: true })
      .then((response) => {
        if (response?.data?.status_code === ResponseCodes.SUCCESS) {
          var data = response.data.data;
          setUsers(data);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchUserData();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "ManageUsers",
    });
  }, []);

  const header = [
    { field: "user_id", headerName: "ID", width: 70 },
    { field: "first_name", headerName: "First name", width: 150 },
    { field: "last_name", headerName: "Last name", width: 150 },
    { field: "last_login", headerName: "Last Login", width: 160 },
    { field: "created", headerName: "Created", width: 160 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (value, row) => `${getUserStatusString(row.status)}`,
    },
  ];

  const customClasses = useCustomStyles();
  return (
    users && (
      <div className={customClasses.root}>
        <Paper className={customClasses.contentPaper}>
          <Typography variant="heading1">Manage Users</Typography>
          <br />
          <br />
          <DataTable tableData={users} header={header} rowIdKey="user_id" />
        </Paper>
      </div>
    )
  );
}

export default ManageUsers;
