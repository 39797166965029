import React from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { Roles } from "../utils/security-utils";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    color: "#FFF",
    minWidth: "35px",
    marginRight: "1rem",
  },
  listItemText: {
    textDecoration: "none",
    color: "#FFF",
    height: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

// Add icons to the menu items
export const leftNavMenuItems = [
  {
    to: "/dashboard",
    label: "Dashboard",
    icon: <DashboardOutlinedIcon />,
    role: Roles.DATA_MANAGER,
  },
  {
    to: "/search",
    label: "Search",
    icon: <SearchIcon />,
    role: Roles.AUTHENTICATED_USER,
  },
  {
    to: "/notes",
    label: "Notes",
    icon: <AutoStoriesOutlinedIcon />,
    role: Roles.AUTHENTICATED_USER,
  },
  {
    to: "/create-individual",
    label: "Create Individual",
    icon: <PersonAddAltOutlinedIcon />,
    role: Roles.DATA_MANAGER,
  },
  {
    to: "/create-family",
    label: "Create Family",
    icon: <GroupAddOutlinedIcon />,
    role: Roles.DATA_MANAGER,
  },
  {
    to: "/manage-users",
    label: "Manage Users",
    icon: <PersonAddAltOutlinedIcon />,
    role: Roles.USER_MANAGER,
  },
  {
    to: "/logout",
    label: "Logout",
    icon: <LogoutIcon />,
    role: Roles.AUTHENTICATED_USER,
  },
];

const MenuItem = ({ to, label, icon, classes }) => (
  <ListItem component={Link} to={to}>
    <ListItemIcon className={classes.listItemIcon}>
      <Tooltip title={label}>{icon}</Tooltip>
    </ListItemIcon>
    <ListItemText primary={label} className={classes.listItemText} />
  </ListItem>
);

const LeftNavMenu = ({ userRoles }) => {
  const classes = useStyles();

  return (
    <div>
      {leftNavMenuItems.map(
        (item) =>
          userRoles?.includes(item.role) && (
            <MenuItem
              key={item.to}
              to={item.to}
              label={item.label}
              icon={item.icon}
              classes={classes}
            />
          )
      )}
    </div>
  );
};

export default LeftNavMenu;
