export function hasRole(roleName, user) {
  if (!user) return false;
  const rolesArray = user.roles.split(",").map((role) => role.trim()); // trim to remove any extra spaces

  return rolesArray.includes(roleName);
}

export const Roles = Object.freeze({
  AUTHENTICATED_USER: "authenticated_user",
  DATA_MANAGER: "data_manager",
  USER_MANAGER: "user_manager",
});

export const UserStatus = Object.freeze({
  ENABLED: 1,
  DISABLED: 0,
});

export function getUserStatusString(status) {
  switch (status) {
    case UserStatus.ENABLED:
      return "Enabled";
    case UserStatus.DISABLED:
      return "Disabled";
    default:
      return "Unknown";
  }
}
