import React, { useState } from "react";
import { Button, Checkbox, Grid, Radio, TextField } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router-dom";
import MonthSelect from "../components/MonthSelect";
import DaySelect from "../components/DaySelect";
import YearField from "../components/YearField";
import { isInteger } from "../utils/data-utils";

const EditIndividualForm = ({ individualData, onSave, isEditing }) => {
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    individual_id: individualData.individual_id,
    first_name: individualData.first_name || "",
    middle_name: individualData.middle_name || "",
    last_name: individualData.last_name || "",
    name_prefix: individualData.name_prefix || "",
    name_suffix: individualData.name_suffix || "",
    alias: individualData.alias || "",
    occupation: individualData.occupation || "",
    father_id: individualData.father_id || "",
    mother_id: individualData.mother_id || "",
    biological_family_fk: individualData.biological_family_fk || "",
    birth_day: individualData.birth_day || "",
    birth_month: individualData.birth_month || "",
    birth_year: individualData.birth_year || "",
    death_day: individualData.death_day || "",
    death_month: individualData.death_month || "",
    death_year: individualData.death_year || "",
    deceased: (individualData.deceased && individualData.deceased === "Y") || false,
    gender: individualData.gender || "O",
    private: (individualData.private && individualData.private === "Y") || false,
  });
  const [firstNameError, setFirstNameError] = useState("");
  const [fatherIDError, setFatherIDError] = useState("");
  const [motherIDError, setMotherIDError] = useState("");
  const navigate = useNavigate();
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === "first_name") {
      if (value.length === 0) {
        setFirstNameError("First name cannot be empty.");
        setErrors((prevErrors) => [...new Set([...prevErrors, "first_name"])]);
      } else {
        setFirstNameError("");
        setErrors((prevErrors) => prevErrors.filter((err) => err !== "first_name"));
      }
    }

    if (field === "father_id") {
      if (value.length > 0 && !isInteger(value)) {
        console.log("Father ID must be an integer.");
        setFatherIDError("Father ID must be an integer.");
        setErrors((prevErrors) => [...new Set([...prevErrors, "father_id"])]);
      } else if (value.length > 0 && value === formData.mother_id) {
        console.log("Father ID cannot be the same as Mother ID.");
        setFatherIDError("Father ID cannot be the same as Mother ID.");
        setErrors((prevErrors) => [...new Set([...prevErrors, "father_id"])]);
      } else {
        setFatherIDError("");
        setErrors((prevErrors) => prevErrors.filter((err) => err !== "father_id"));
        if (motherIDError === "Mother ID cannot be the same as Father ID.") {
          setMotherIDError("");
          setErrors((prevErrors) => prevErrors.filter((err) => err !== "mother_id"));
        }
      }
    }

    if (field === "mother_id") {
      if (value.length > 0 && !isInteger(value)) {
        console.log("Mother ID must be an integer.");
        setMotherIDError("Mother ID must be an integer.");
        setErrors((prevErrors) => [...new Set([...prevErrors, "mother_id"])]);
      } else if (value.length > 0 && value === formData.father_id) {
        console.log("Mother ID cannot be the same as Father ID.");
        setMotherIDError("Mother ID cannot be the same as Father ID.");
        setErrors((prevErrors) => [...new Set([...prevErrors, "mother_id"])]);
      } else {
        setMotherIDError("");
        setErrors((prevErrors) => prevErrors.filter((err) => err !== "mother_id"));
        if (fatherIDError === "Father ID cannot be the same as Mother ID.") {
          setFatherIDError("");
          setErrors((prevErrors) => prevErrors.filter((err) => err !== "father_id"));
        }
      }
    }
  };

  const handleCheckboxChange = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: !prevData[field],
    }));
  };

  // Validates form data
  const isValidateFormData = () => {
    return errors.length === 0;
  };

  const handleCancel = () => {
    // Navigate back to dashboard
    navigate("/dashboard");
  };
  const handleSave = () => {
    if (formData.first_name.length === 0) {
      setFirstNameError("First name cannot be empty.");
      setErrors((prevErrors) => [...new Set([...prevErrors, "first_name"])]);
      return;
    }
    if (isValidateFormData()) {
      onSave(formData);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <TextField
          label="First Name"
          value={formData.first_name}
          onChange={(e) => handleInputChange("first_name", e.target.value)}
          error={Boolean(firstNameError)}
          helperText={firstNameError}
          autoFocus={!isEditing}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Middle Name"
          value={formData.middle_name}
          onChange={(e) => handleInputChange("middle_name", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Last Name"
          value={formData.last_name}
          onChange={(e) => handleInputChange("last_name", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Alias"
          value={formData.alias}
          onChange={(e) => handleInputChange("alias", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Title"
          value={formData.name_prefix}
          onChange={(e) => handleInputChange("name_prefix", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Suffix"
          value={formData.name_suffix}
          onChange={(e) => handleInputChange("name_suffix", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Occupation"
          value={formData.occupation}
          onChange={(e) => handleInputChange("occupation", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Father"
          value={formData.father_id}
          onChange={(e) => handleInputChange("father_id", e.target.value)}
          error={Boolean(fatherIDError)}
          helperText={fatherIDError}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Mother"
          value={formData.mother_id}
          onChange={(e) => handleInputChange("mother_id", e.target.value)}
          error={Boolean(motherIDError)}
          helperText={motherIDError}
        />
      </Grid>
      <Grid item xs={12} md={6} />

      <Grid item xs={12} md={1.1}>
        <DaySelect
          label="Birth Day"
          value={formData.birth_day}
          onChange={(event) => handleInputChange("birth_day", event)}
        />
      </Grid>
      <Grid item xs={12} md={1.6}>
        <MonthSelect
          label="Birth Month"
          value={formData.birth_month}
          onChange={(event) => handleInputChange("birth_month", event)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <YearField
          label="Birth Year"
          value={formData.birth_year}
          onChangeHandler={handleInputChange}
          onChangeLabel="birth_year"
          setErrors={setErrors}
        />
      </Grid>

      <Grid item xs={12} md={1.1}>
        <DaySelect
          label="Death Day"
          value={formData.death_day}
          onChange={(event) => handleInputChange("death_day", event)}
        />
      </Grid>
      <Grid item xs={12} md={1.6}>
        <MonthSelect
          label="Death Month"
          value={formData.death_month}
          onChange={(event) => handleInputChange("death_month", event)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <YearField
          label="Death Year"
          value={formData.death_year}
          onChangeHandler={handleInputChange}
          onChangeLabel="death_year"
          setErrors={setErrors}
        />
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.deceased}
              onChange={() => handleCheckboxChange("deceased")}
            />
          }
          label="Deceased"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.private}
              onChange={() => handleCheckboxChange("private")}
            />
          }
          label="Private"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl component="fieldset" style={{ marginTop: "1rem" }}>
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender"
            value={formData.gender}
            onChange={(e) => handleInputChange("gender", e.target.value)}
          >
            <FormControlLabel value="M" control={<Radio />} label="Male" />
            <FormControlLabel value="F" control={<Radio />} label="Female" />
            <FormControlLabel value="O" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleSave}
          style={{ marginRight: "1rem" }}
          disabled={!isValidateFormData()}
        >
          {isEditing ? "Save" : "Create"}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditIndividualForm;
