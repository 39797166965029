import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Typography, Paper, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import { getFormattedDate } from "../utils/DateTime";
import ReactGA from "react-ga4";
import useCustomStyles from "../styles/customStyles";
import { useAuth } from "../context/AuthContext";
import { hasRole, Roles } from "../utils/security-utils";
import { useNavigate } from "react-router-dom";
import DataTable from "../components/DataTable";
import {
  Search as SearchIcon,
  AccountTree as PedigreeIcon,
  PersonSearch as ViewIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
    width: "95%",
  },
  searchInput: {
    width: "100%",
  },
  searchButton: {
    marginTop: theme.spacing(2),
  },
  searchResults: {
    width: "100%",
    padding: "1rem",
  },
  list: {
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
  },
  link: {
    backgroundColor: "inherit",
    padding: 0,
    textDecoration: "none",
  },
}));

function Search() {
  const { user } = useAuth();
  const title = "Search";
  const isDataManager = hasRole(Roles.DATA_MANAGER, user);
  const resultsRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: title,
    });
    // eslint-disable-next-line
  }, []);
  const MIN_SEARCH_SIZE = 4;
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState("");
  const apiUrl = getApiUrl() + "individual/search";

  const actions = [
    {
      label: "View",
      icon: <ViewIcon />,
      link: (id) => `/card/${id}`, // Dynamic URL based on row ID
    },
    {
      label: "Pedigree Chart",
      icon: <PedigreeIcon />,
      link: (id) => `/pedigree/${id}`,
    },
    ...(isDataManager
      ? [
          {
            label: "Edit",
            icon: <EditIcon />,
            link: (id) => `/edit-individual/${id}`,
          },
        ]
      : []),
  ];

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Validate the input and set the error state accordingly
    if (value.length < MIN_SEARCH_SIZE) {
      setError(`Search term must be at least ${MIN_SEARCH_SIZE} characters`);
    } else {
      setError("");
    }
  };

  // If the user clicks on the search button
  // execute the search
  const handleSearchSubmit = () => {
    if (searchTerm.length < MIN_SEARCH_SIZE) {
      setError(`Search term must be at least ${MIN_SEARCH_SIZE} characters`);
    } else {
      setError("");
      executeSearch(searchTerm);
    }
  };

  // If the user hits the return key execute the search
  const onKeyPress = (event) => {
    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
      handleSearchSubmit();
    }
  };

  const executeSearch = (searchToken) => {
    ReactGA.event({
      category: "search-action",
      action: "click-search-button",
      label: "submit-search", // optional
    });

    const params = new URLSearchParams([["value", searchToken]]);

    axios
      .get(apiUrl, {
        params,
        withCredentials: true, // Enable sending cookies
      })
      .then((response) => {
        if (response?.data?.status_code === ResponseCodes.SUCCESS) {
          setSearchResults(response.data.data);
          //console.log("Search results: " + JSON.stringify(response.data.data));

          setTableData(response.data.data);
          // Scroll to results table after the search is completed
          if (resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: "smooth" });
          }
        } else if (response?.data?.status_code === ResponseCodes.FAIL_UNAUTHORIZED) {
          navigate("/login");
        }
      });
  };

  // Add useEffect to scroll to the results when tableData is updated
  useEffect(() => {
    if (tableData?.length > 0 && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableData]);

  const header = [
    { field: "first_name", headerName: "First Name", width: 150 },
    { field: "middle_name", headerName: "Middle Name", width: 150 },
    { field: "last_name", headerName: "Last Name", width: 150 },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      width: 150,
      valueGetter: (value, row) =>
        getFormattedDate(row.birth_year, row.birth_month, row.birth_day),
    },
    { field: "name_prefix", headerName: "Title" },
    { field: "alias", headerName: "Alias", width: 150 },
  ];

  return (
    <div className={customClasses.root}>
      <Paper className={customClasses.contentPaper}>
        <Typography variant="h4" className={classes.heading}>
          Search for a name:
        </Typography>
        <TextField
          className={classes.searchInput}
          variant="outlined"
          id="searchInput"
          onKeyDown={onKeyPress}
          onChange={handleSearchChange}
          label="Name Search"
          value={searchTerm}
          error={Boolean(error)}
          helperText={error}
          autoFocus={true}
        />

        <Button
          className={classes.searchButton}
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={handleSearchSubmit}
        >
          Search
        </Button>

        {tableData?.length > 0 && (
          <DataTable
            tableData={tableData}
            header={header}
            rowIdKey="individual_id"
            checkboxSelection={false}
            rowClickUrl={"/card/"}
            actions={actions}
          />
        )}
      </Paper>

      {!searchResults && (
        <Paper className={classes.searchResults}>
          <Typography variant="bodyText">
            Unable to find any search results for{" "}
            <div style={{ display: "inline", fontWeight: "bold" }}>{searchTerm}</div>.
          </Typography>
        </Paper>
      )}
    </div>
  );
}

export default Search;
