import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { styled, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import iconImage from "../images/palm.png";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Routes from "../navigation/Router";
import LeftNavMenu, { leftNavMenuItems } from "../navigation/LeftNavMenu";
import AvatarWithMenu from "../components/Avatars";

const mobileDrawerWidth = 150;
const desktopDrawerWidth = 220;

const StyledDesktopDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? desktopDrawerWidth : `calc(${theme.spacing(7)} + 1px)`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    width: open ? desktopDrawerWidth : `calc(${theme.spacing(7)} + 1px)`,
    backgroundColor: "#45363A !important",
    marginTop: "4rem !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.3s",
    }),
  },
}));

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: "#35363A",
    },
    content: {
      backgroundColor: grey[200], // main content background
      padding: "2rem",
      marginTop: "4rem",
      transition: "margin 0.3s ease-in-out",
    },
    navButton: {
      textDecoration: "none",
      color: "#FFF",
    },
    toolbarListItem: {
      paddingRight: "2rem",
      paddingLeft: 0,
    },
    logo: {
      height: 30,
      marginRight: 8,
    },
    title: {
      flexGrow: 1,
      fontSize: "1.5rem",
      fontFamily: "Ubuntu",
      fontWeight: "550",
      letterSpacing: "0.1rem",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        height: 20,
      },
    },
    menuButton: {
      [theme.breakpoints.down("sm")]: {
        marginRight: "auto", // Align the menu button to the right on phones
      },
    },
    list: {
      display: "flex",
    },
    marginBottomAuth: {
      marginBottom: "8rem",
    },
    marginBottomUnauth: {
      marginBottom: "6rem",
    },
    "@media (max-width: 600px)": {
      root: {
        marginBottom: "5.5rem",
      },
    },
  };
});

const CustomAppBar = (props) => {
  const { isAuthenticated, user } = useAuth();
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const theme = useTheme();
  const updateSelected = (index) => {};

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [desktopDrawerOpen, setDesktopDrawerOpen] = useState(true);

  const handleMobileDrawerOpen = () => {
    setMobileDrawerOpen(true);
  };

  const handleMobileDrawerClose = () => {
    setMobileDrawerOpen(false);
  };

  const handleDesktopDrawerToggle = () => {
    let action = !desktopDrawerOpen;
    setDesktopDrawerOpen(action);
  };

  useEffect(() => {}, [isAuthenticated]);

  function getAllNavMenuItems(isPhone, loggedIn, isMainNav) {
    let menuItems = [];
    if (isPhone || isMainNav) {
      menuItems = [
        ...menuItems,
        {
          name: "Home",
          linkTo: "/",
        },
        {
          name: "Contact",
          linkTo: "/contact",
        },
      ];
    }
    if (!loggedIn) {
      menuItems = [
        ...menuItems,
        {
          name: "Login",
          linkTo: "/login",
        },
      ];
    }
    return menuItems;
  }

  function getOneNavMenuItem(item, index) {
    return (
      <ListItem
        className={classes.toolbarListItem}
        key={"navItem-" + index}
        component={Link}
        to={item.linkTo}
        onClick={updateSelected}
      >
        <ListItemText primary={item.name} className={classes.navButton} />
      </ListItem>
    );
  }
  // Conditionally apply styles based on the isAuthenticated state
  const rootClassName = [
    classes.root,
    isAuthenticated ? classes.marginBottomAuth : classes.marginBottomUnauth,
  ].join(" ");

  return (
    <div className={rootClassName}>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          {/**  SMALL device (phone) menu button */}
          {isPhone && (
            <IconButton
              className={classes.menuButton}
              edge="start" // Align the icon to the right
              color="inherit"
              aria-label="menu"
              onClick={handleMobileDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/* DESKTOP menu button */}
          {isAuthenticated && !isPhone && (
            <IconButton
              className={classes.menuButton}
              edge="start" // Align the icon to the right
              color="inherit"
              aria-label="menu"
              onClick={handleDesktopDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/** Main toolbar menu options (unauthenticated) */}
          <Link to={"/"}>
            <IconButton color="inherit" aria-label="home">
              <img src={iconImage} alt="Logo" className={classes.logo} />
            </IconButton>
          </Link>
          <Typography className={classes.title}>Ceylon Tamils Genealogy</Typography>
          {!isPhone && (
            <List className={classes.list}>
              {getAllNavMenuItems(isPhone, isAuthenticated, true).map((item, index) =>
                getOneNavMenuItem(item, index)
              )}
            </List>
          )}

          {isAuthenticated && !isPhone && (
            <AvatarWithMenu firstName={user?.firstName} lastName={user?.lastName} />
          )}
        </Toolbar>
      </AppBar>

      {/* SMALL device drawer */}
      <Drawer
        anchor="left"
        open={mobileDrawerOpen}
        onClose={handleMobileDrawerClose}
        sx={{ "& .MuiDrawer-paper": { width: mobileDrawerWidth } }}
      >
        <List>
          {getAllNavMenuItems(isPhone, isAuthenticated, false).map((item, index) => (
            <ListItem
              key={index}
              component={Link}
              to={item.linkTo}
              onClick={handleMobileDrawerClose}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}

          {leftNavMenuItems.map(
            (item) =>
              user?.roles?.includes(item.role) && (
                <ListItem
                  key={item.to}
                  component={Link}
                  to={item.to}
                  onClick={handleMobileDrawerClose}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      {/* DESKTOP authenticated user drawer */}
      {isAuthenticated && !isPhone ? (
        <StyledDesktopDrawer variant="permanent" open={desktopDrawerOpen}>
          <List>
            <LeftNavMenu userRoles={user?.roles} />
          </List>
        </StyledDesktopDrawer>
      ) : null}
      <main
        className={classes.content}
        style={{
          marginLeft:
            isPhone || !isAuthenticated
              ? 0
              : desktopDrawerOpen
              ? desktopDrawerWidth
              : `calc(${theme.spacing(7)} + 1px)`,
          transition: "margin 0.3s ease-in-out", // Smooth transition for margin change
        }}
      >
        <Routes />
      </main>
    </div>
  );
};

export default CustomAppBar;
