import React, { useEffect, useState } from "react";
import { Paper, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import { Icon } from "@mui/material";
import TombstoneIcon from "../images/tombstone.svg";
import MarriageIcon from "../images/marriage.svg";
import OccupationIcon from "../images/occupation.svg";
import BabyStrollerIcon from "../images/baby-stroller.svg";
import { CameraAlt, Launch as LaunchIcon } from "@mui/icons-material";
import axios from "axios";
import { getApiUrl, ResponseCodes } from "../utils/network-utils";
import { getFormattedDate } from "../utils/DateTime";
import PhotoModal from "./PhotoModal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    width: "100%",
  },
  timelineTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  viewPhotoButton: {
    marginTop: "0.5rem",
  },
  "@media (max-width: 800px)": {
    timelineContainer: {
      //width: "100%",
    },
    timelineTitle: {
      fontSize: "0.9rem",
    },
    timelineText: {
      fontSize: "0.6rem",
    },
    viewPhotoButton: {
      fontSize: "0.7rem",
    },
  },
}));

const SERVER_URL = window.location.origin + "/";
const FAMILY_API_URL = getApiUrl() + "family/get";
const CHILDREN_API_URL = getApiUrl() + "family/children";
const INDIVIDUAL_API_URL = getApiUrl() + "individual/get";
const IMAGES_API_URL = getApiUrl() + "individual/images";

// Get the full name of an individual as a string
function getNameOfIndividual(individual) {
  var name = "Unknown";
  if (individual) {
    const prefix = individual.name_prefix ? individual.name_prefix : "";
    const firstName = individual.first_name ? individual.first_name : "";
    const middleName = individual.middle_name ? individual.middle_name : "";
    const lastName = individual.last_name ? individual.last_name : "";
    const suffix = individual.name_suffix ? individual.name_suffix : "";
    name = prefix + " " + firstName + " " + middleName + " " + lastName + " " + suffix;
  }
  return name;
}

// Main function for this component
// -----------------------------------
function CustomTimeline(props) {
  const classes = useStyles();
  const [father, setFather] = useState(null);
  const [mother, setMother] = useState(null);
  const [children, setChildren] = useState([]);
  const [images, setImages] = useState([]);

  //console.log("Resetting individual to " + props.individual.individual_id);
  const individual = props.individual;

  // Navigate to the card page specific to an individual
  // with the given ID
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    // reset all values
    setFather("");
    setMother("");
    setChildren("");
    setImages("");
    props.fetchIndividualData(id);
    navigate(`/card/${id}`);
    // Scroll to the top of the page when the new page loads
    window.scrollTo(0, 0);
  };
  const handleNavigateMother = () => {
    handleNavigate(mother.individual_id);
  };
  const handleNavigateFather = () => {
    handleNavigate(father.individual_id);
  };
  const handleNavigateChild = (id) => {
    handleNavigate(id);
  };

  //console.log("Individual passed in props: " + individual.individual_id);

  const fetchTimelineData = async () => {
    try {
      // ----- BIOLOGICAL FAMILY -----
      if (individual.biological_family_fk) {
        const biologicalFamilyParams = new URLSearchParams([
          ["id", individual.biological_family_fk],
        ]);

        // Call API to get spouse family details if any
        const responseBiologicalFamily = await axios.get(FAMILY_API_URL, {
          params: biologicalFamilyParams,
          withCredentials: true,
        });
        //console.log("Biological Family Response Data: " + JSON.stringify(responseBiologicalFamily.data));

        // ----- MOTHER ----
        if (responseBiologicalFamily.data.data[0].wife_id) {
          const motherParams = new URLSearchParams([
            ["id", responseBiologicalFamily.data.data[0].wife_id],
          ]);

          // Call API to get mother details if any
          const responseMother = await axios.get(INDIVIDUAL_API_URL, {
            params: motherParams,
            withCredentials: true,
          });
          //console.log("Mother Response Data: " + JSON.stringify(responseMother.data));

          if (
            responseMother.data.status_code === ResponseCodes.SUCCESS &&
            responseMother.data.data[0]
          ) {
            setMother(responseMother.data.data[0]);
          }
        }
        // ----- FATHER ----
        //console.log("father husband id: " + responseBiologicalFamily.data.data[0].husband_id);
        if (responseBiologicalFamily.data.data[0].husband_id) {
          const fatherParams = new URLSearchParams([
            ["id", responseBiologicalFamily.data.data[0].husband_id],
          ]);

          // Call API to get father details if any
          const responseFather = await axios.get(INDIVIDUAL_API_URL, {
            params: fatherParams,
            withCredentials: true,
          });
          //console.log("Father Response Data: " + JSON.stringify(responseFather.data));

          if (
            responseFather.data.status_code === ResponseCodes.SUCCESS &&
            responseFather.data.data[0]
          ) {
            setFather(responseFather.data.data[0]);
          }
        }
      }

      // ----- CHILDREN -----
      // Call API to get children details if any
      if (individual.marriages) {
        const fetchChidlren = async (marriages) => {
          await Promise.all(
            marriages.map(async (marriage) => {
              const spouseFamilyParams = new URLSearchParams([
                ["id", marriage.family_id],
              ]);
              const responseChildren = await axios.get(CHILDREN_API_URL, {
                params: spouseFamilyParams,
                withCredentials: true,
              });

              if (responseChildren.data.status_code === ResponseCodes.SUCCESS) {
                //console.log("API Children: " + responseChildren.data?.data?.length);
                setChildren(responseChildren.data.data);
              }
            })
          );
        };
        fetchChidlren(individual.marriages);
      }

      // ----- IMAGES (PHOTOS) -----
      // Call API to get images if any
      const individualParams = new URLSearchParams([["id", individual.individual_id]]);
      const responseImages = await axios.get(IMAGES_API_URL, {
        params: individualParams,
        withCredentials: true,
      });

      if (responseImages.data.status_code === ResponseCodes.SUCCESS) {
        //console.log("Images: " + JSON.stringify(responseImages.data));
        setImages(responseImages.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get spouse family details if any
  useEffect(() => {
    //console.log("RUN ONCE - CustomTimeline");
    fetchTimelineData();
    // eslint-disable-next-line
  }, [individual]);

  // Birth timeline event
  // ----------------------
  const getBirthEvent = (individual, father, mother) => {
    var gender =
      individual.gender && individual.gender === "M"
        ? "boy"
        : individual.gender === "F"
        ? "girl"
        : "";
    var genderColor =
      individual.gender && individual.gender === "M"
        ? "boy"
        : individual.gender === "F"
        ? "girl"
        : "default";

    return (
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {getFormattedDate(
            individual.birth_year,
            individual.birth_month,
            individual.birth_day
          )}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color={genderColor} variant="outlined">
            <ChildCareIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography className={classes.timelineTitle}>Birth</Typography>
          <Typography className={classes.timelineText}>
            A baby {gender} is born {getParentsNames(father, mother)}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  // Get links to parents for timeline
  const getParentsNames = (father, mother) => {
    if (!father && !mother) {
      return "";
    }

    // get parent names
    const fatherName = getNameOfIndividual(father);
    const motherName = getNameOfIndividual(mother);

    const twoParents = mother && father;

    return (
      <span>
        {" to "}
        {mother && (
          <span>
            <Link
              to={`/card/${mother.individual_id}`}
              style={{ textDecoration: "none" }}
              onClick={handleNavigateMother}
            >
              {motherName}
            </Link>
          </span>
        )}
        {twoParents && <span> and </span>}
        {father && (
          <span>
            <Link
              to={`/card/${father.individual_id}`}
              style={{ textDecoration: "none" }}
              onClick={handleNavigateFather}
            >
              {fatherName}
            </Link>
          </span>
        )}
      </span>
    );
  };

  // Occupation timeline event
  // ----------------------
  const getOccupationEvent = (individual) => {
    if (!individual || !individual.occupation) {
      return "";
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="occupation" variant="outlined">
            <Icon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={OccupationIcon} height={20} width={20} alt="Briefcase" />
            </Icon>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography className={classes.timelineTitle}>Occupation</Typography>
          <Typography className={classes.timelineText}>
            {individual.occupation}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  // Marriage timeline event
  // ------------------------
  const getMarriageEvent = (individual) => {
    if (!individual || !individual.marriages) {
      return "";
    }
    //console.log("Spouse: " + JSON.stringify(spouse));
    //console.log("Family: " + JSON.stringify(family));
    const jsxElements = individual.marriages.map((marriage, index) => {
      //console.log("Marriage: " + JSON.stringify(marriage));

      if (!marriage.spouse_id) return "";

      const individualName = getNameOfIndividual(individual);
      const spouseName = " " + getNameOfIndividual(marriage);
      const marriedDate = getFormattedDate(
        marriage.marriage_year,
        marriage.marriage_month,
        marriage.marriage_day
      );
      const key = "marriage_" + index;

      return (
        <TimelineItem key={key}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {marriedDate}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="marriage" variant="outlined">
              <Icon
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={MarriageIcon} height={20} width={20} alt="Wedding Rings" />
              </Icon>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography className={classes.timelineTitle}>Married</Typography>
            <Typography className={classes.timelineText}>
              {individualName} married
              <Link
                to={`/card/${marriage.spouse_id}`}
                style={{ textDecoration: "none" }}
                onClick={() => handleNavigate(marriage.spouse_id)}
              >
                {spouseName}
              </Link>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      );
    });
    return jsxElements;
  };

  // Returns timeline events for each child
  const getChildrenEvents = (individual, children) => {
    if (!individual || !children || children.length === ResponseCodes.SUCCESS) {
      return "";
    }

    const jsxElements = children.map((child, index) => {
      const name = getNameOfIndividual(child);
      const birthDate = getFormattedDate(
        child.birth_year,
        child.birth_month,
        child.birth_day
      );
      const genderColor = child.gender && child.gender === "M" ? "boy" : "girl";
      const genderChild = child.gender && child.gender === "M" ? "son" : "daughter";

      return (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {birthDate}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color={genderColor} variant="outlined">
              <Icon
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={BabyStrollerIcon} height={20} width={20} alt="baby stroller" />
              </Icon>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography className={classes.timelineTitle}>Child Birth</Typography>
            <Typography className={classes.timelineText}>
              Their {genderChild},
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={`/card/${child.individual_id}`}
                onClick={() => handleNavigateChild(child.individual_id)}
              >
                {" "}
                {name}
              </Link>{" "}
              is born
            </Typography>
          </TimelineContent>
        </TimelineItem>
      );
    });

    return jsxElements;
  };

  // Photo timeline event
  // ----------------------
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPhotoUrl, setCurrentPhotoUrl] = useState("");
  const openModal = (image) => {
    const photoUrl = SERVER_URL + "photos/" + image.album_path + "/" + image.full_path;
    setCurrentPhotoUrl(photoUrl);
    //console.log("Photo URL: " + photoUrl);
    setModalOpen(true);
  };
  const getPhotoEvent = (images, isModalOpen, setModalOpen) => {
    if (!images || images.length === ResponseCodes.SUCCESS) return null;

    const closeModal = () => setModalOpen(false);

    //console.log(photoUrl);

    return (
      <>
        {images.map((image, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {getFormattedDate(image.date_year, image.date_month, image.date_day)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot variant="outlined">
                <CameraAlt />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography className={classes.timelineTitle}>Photo</Typography>
              <Typography className={classes.timelineText}>
                {image.description}
              </Typography>
              <Button
                className={classes.viewPhotoButton}
                variant="contained"
                color="primary"
                startIcon={<LaunchIcon />}
                onClick={() => openModal(image)}
                size="small"
              >
                View
              </Button>
            </TimelineContent>
          </TimelineItem>
        ))}
        <PhotoModal open={isModalOpen} onClose={closeModal} photoUrl={currentPhotoUrl} />
      </>
    );
  };

  // Death timeline event
  // ------------------------
  const getDeathEvent = (individual) => {
    if (
      !individual ||
      (!individual.death_year && !individual.deceased) ||
      (individual.deceased && individual.deceased === "N")
    ) {
      return "";
    }

    const deathDate = getFormattedDate(
      individual.death_year,
      individual.death_month,
      individual.death_day
    );

    return (
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {deathDate}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="death" variant="outlined">
            <Icon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={TombstoneIcon} height={20} width={20} alt="tomb stone" />
            </Icon>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography className={classes.timelineTitle}>Death</Typography>
          <Typography className={classes.timelineText}>Rest in peace.</Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };
  return (
    <Paper className={classes.timelineContainer}>
      <Timeline position="alternate">
        {getBirthEvent(individual, father, mother)}
        {getMarriageEvent(individual)}
        {getOccupationEvent(individual)}
        {getPhotoEvent(images, isModalOpen, setModalOpen)}
        {getChildrenEvents(individual, children)}
        {getDeathEvent(individual)}
      </Timeline>
    </Paper>
  );
}

export default CustomTimeline;
