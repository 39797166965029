export function getEmptyIndividual() {
  const individual = {
    individual_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    name_prefix: "",
    name_suffix: "",
    alias: "",
    occupation: "",
    birth_day: "",
    birth_month: "",
    birth_year: "",
    death_day: "",
    death_month: "",
    death_year: "",
    deceased: "N",
    gender: "M",
    private: "N",
  };
  return individual;
}

export function getEmptyFamily() {
  const family = {
    family_id: "",
    husband_id: "",
    wife_id: "",
    marriage_day: "",
    marriage_month: "",
    marriage_year: "",
    marriage_era: "",
    divorce_day: "",
    divorce_month: "",
    divorce_year: "",
    divorce_era: "",
  };
  return family;
}

// Returns an array containing only updated data
// as well as mandatory data, e.g. individual_id
export function detectChanges(currentData, newData) {
  const changes = {};

  for (const key in newData) {
    if (newData[key] == null) {
      newData[key] = "";
    }

    if (
      newData.hasOwnProperty(key) &&
      newData[key] !== currentData[key] &&
      !(newData[key] === "" && !currentData[key])
    ) {
      changes[key] = {
        old_value: currentData[key] ?? "",
        new_value: newData[key],
      };
    }
  }
  return changes;
}

export function isInteger(value) {
  // eslint-disable-next-line
  return !isNaN(value) && parseInt(value, 10) == value;
}
