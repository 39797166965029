import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, Link } from "react-router-dom";

const paginationModel = { page: 0, pageSize: 50 };

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      fontWeight: "bold", // Bold header text
      backgroundColor: "#f5f5f5", // Optional: Light background color
    },
    border: 0,
    marginTop: "1rem",
  },
});

export default function DataTable({
  tableData,
  header,
  rowIdKey = "id",
  checkboxSelection = true,
  rowClickUrl = "",
  actions,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleRowClick = (param) => {
    if (rowClickUrl?.length === 0) {
      return;
    }
    navigate(rowClickUrl + param.row[rowIdKey]);
  };

  const actionColumn = {
    field: "actions",
    headerName: "Actions",
    width: 250,
    renderCell: (params) => (
      <>
        {/* Render the actions dynamically */}
        {actions?.map((action, index) => {
          return (
            <IconButton
              key={index}
              component={Link}
              to={action.link(params.row[rowIdKey])}
              aria-label={action.label}
              onClick={(e) => e.stopPropagation()} // Prevent row click when action is clicked
            >
              {action.icon}
            </IconButton>
          );
        })}
      </>
    ),
  };

  // Add the action column to the header
  const updatedColumns = [...header, actionColumn];

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={tableData}
        columns={updatedColumns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection={checkboxSelection}
        className={classes.root}
        getRowId={(row) => row[rowIdKey]}
        onRowClick={handleRowClick}
      />
    </Paper>
  );
}
